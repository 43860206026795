import React, { Component } from 'react';
import Link from 'next';
import Meta from './Meta';
import Header from './Header';
import Nav from './Nav';
import { useRouter } from 'next/router';

const Page = (props) => {
  let router = useRouter();
  return (
    <div className="">
      <Meta />
      <div className="relative flex flex-col h-screen border-b-8 border-phoenix-red">
        <Header />
        <Nav className="z-0" />
        <main className="flex-1 h-full h-screen overflow-y-auto bg-black focus:outline-none">
          <div className="z-20 h-full mx-auto">{props.children}</div>
        </main>
      </div>
    </div>
  );
};

export default Page;
