import styled from 'styled-components';
import Router from 'next/router';
import NProgress from 'nprogress';

Router.onRouteChangeStart = () => {
  NProgress.start();
};

Router.onRouteChangeComplete = () => {
  NProgress.done();
};
Router.onRouteChangeError = () => {
  NProgress.done();
};

const StyledHeader = styled.header`
  .bar {
   /* border-bottom: 10px solid ${(props) => props.theme.black}; */
   /* box-shadow: ${(props) => props.theme.bs}; */
    display: grid;
    grid-template-columns: auto 1fr;
    justify-content: space-between;
    align-items: stretch;
    @media (max-width: 1300px) {
      grid-template-columns: 1fr;
      justify-content: center;
    }
  }
  .sub-bar {
    display: grid;
    grid-template-columns: 1fr auto;
    border-bottom: 1px solid ${(props) => props.theme.lightgrey};
  }
  
  /* a {
    letter-spacing: -0.055em;
    font-weight: 740;
  } */
`;

const Header = () => (
  <StyledHeader>
    <div className=""></div>
  </StyledHeader>
);

export default Header;
