import App from 'next/app';
import Page from '../components/Page';
import { ApolloProvider } from 'react-apollo';

import withData from '../lib/withData';
import '../public/static/tailwind.css';

function MyApp(props) {
  const { Component, apollo, pageProps } = props;
  return (
    <div>
      <ApolloProvider client={apollo}>
        <Page>
          {' '}
          <Component {...pageProps} />
        </Page>
      </ApolloProvider>
    </div>
  );
}

MyApp.getInitialProps = async ({ Component, ctx }) => {
  let pageProps = {};
  if (Component.getInitialProps) {
    pageProps = await Component.getInitialProps(ctx);
  }
  //this exposes the query to the user
  pageProps.query = ctx.query;
  return { pageProps };
};

export default withData(MyApp);
