// This is client side config only - don't put anything in here that shouldn't be public!
export const endpoint = `http://localhost:4444`;
// export const prodEndPoint = `http://localhost:4444`;
export const prodEndPoint = `https://portal-backend.usephoenix.com`;
// export const prodEndPoint = `https://portal-backend-stg.usephoenix.com`;
export const frontend = `http://localhost:7777`;
// export const prodFrontend = `http://localhost:7777`;
export const prodFrontend = 'https://portal.usephoenix.com';
// export const prodFrontend = 'https://portal-stg.usephoenix.com';
export const STRIPE_PUBLIC_KEY = `pk_live_c1uz1fBbzwTuO02qFMpBMPOU00JoOlOLCS`;
// export const STRIPE_PUBLIC_KEY = `pk_test_3ba8cAzR2uKLF5Yx0OYbwk7800JOkLErgu`;
