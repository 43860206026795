import Link from 'next/link';
import User from './wrappers/User';
import { Logo, ReportIcon, UserIcon } from './styles/Icons';
import Error from '../components/ErrorMessage';
import classNames from 'classnames';
import Router, { useRouter } from 'next/router';

const Nav = () => {
  let { pathname } = useRouter();

  return (
    <User>
      {({ data, error, loading }) => {
        let loggedInNavClass = classNames(
          'flex',
          'bg-black',
          'sm:bg-phoenix-gray-dark sm:border-t-4 sm:border-phoenix-red'
        );

        let loggedOutNavClass = classNames(
          'flex',
          'bg-black',
          'sm:bg-phoenix-gray-dark sm:border-t-4 sm:border-phoenix-red',
          'sm:hidden'
        );

        let activeLink = classNames('nav-link', 'active');

        // Redirect if logged in.
        if (pathname && pathname === '/' && data?.me) {
          if (data?.me?.belongsToCompany?.isSubscribed) {
            Router.push('/reports');
          } else {
            Router.push('/account');
          }
        }

        let me;
        if (data) {
          me = data.me;
        }

        // Independent navbar handling for the / page.
        // if (pathname === '/') {
        //   return (
        //     <div className={loginNav}>
        //       <PhoenixLogo />
        //       <div className="flex items-end justify-end"></div>
        //     </div>
        //   );
        // }

        let navClass = !me ? loggedOutNavClass : loggedInNavClass;

        return (
          <>
            <div className="sm:border-b-2 sm:border-phoenix-gray-light">
              <div className={navClass}>
                <div className="flex justify-between w-full h-full sm:max-w-7xl sm:px-6 sm:mx-auto">
                  {!me ? (
                    <PhoenixLogo className="flex sm:hidden" />
                  ) : (
                    <PhoenixLogo className="hidden sm:flex" />
                  )}
                  <div className="sticky top-0 w-full sm:w-auto sm:block">
                    {me && (
                      <div className="flex w-full h-full border-b-2 divide-x-2 divide-phoenix-gray-light border-phoenix-gray-light sm:divide-x-0 sm:border-none">
                        {' '}
                        <div className="nav-link-background">
                          <div
                            className={
                              pathname === '/reports' ? activeLink : 'nav-link'
                            }
                          >
                            <Link href="/reports">
                              <a>
                                <ReportIcon className="w-6 h-6 mr-3 text-phoenix-red" />
                                Reports
                              </a>
                            </Link>
                          </div>
                        </div>
                        <div className="nav-link-background">
                          <div
                            className={
                              pathname === '/account' ? activeLink : 'nav-link'
                            }
                          >
                            <Link href="/account">
                              <a>
                                <UserIcon className="w-6 h-6 mr-3 text-phoenix-red" />
                                Account
                              </a>
                            </Link>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <Error error={error} />
          </>
        );
      }}
    </User>
  );
};

let PhoenixLogo = (props) => {
  let logoClass = classNames(props.className, 'items-center');
  return (
    <div className={logoClass}>
      <Logo keyName={'navlogo'} className="w-24 h-24" />
      <div className="ml-6">
        <p className="text-4xl font-black leading-none text-white uppercase ">
          Phoenix
        </p>
        <p className="text-lg font-black leading-none tracking-wider text-white uppercase ">
          DryLINK Subscriptions
        </p>
      </div>
    </div>
  );
};

export default Nav;
